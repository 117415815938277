@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

.container-preview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    gap: 2em 4em;
    grid-auto-flow: row;
    width: 100%;
    padding: 2em 9em 3em 8em;
    align-items: start;
  }

#chapitres {
    background-color: #989898;
}

.rectangle11 {
    padding-left: 15%;
    padding-right: 12%;
    padding-top: 60px;
    padding-bottom: 60px;
}

.titre-chapitres {
    font-size: 30px;
    font-weight: normal;
    padding-top: 10px;
}

.rectangle12 {
    padding-right: 0px;
    display: flex;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-template-rows: 1fr 0fr;

}
.latest {
    color: var(--color-000);
    font-weight: 800;
    padding-top: 40px;
    position: relative;
    font-size: 1.7em;
    padding-left: 4.7em;
  }
  
  .serie-name {
    font-weight: 800;
    font-size: 1.55em;
  }
  
  .chapter-number {
      display: flex;
      gap: 8px;
      font-size: 1.1em;
      flex-direction: column;
  }
  
  .chapter-number p {
    cursor: default;
    font-size: 0.9em;
    color: var(--color-BS2);
  }
  .chapter-number h4 {
    color: var(--color-BS1);
  }
  
  h4 {
    font-weight: 600;
  }
.button {
    border: none;
    margin-right: 20px;
    font-size: 25px;
    font-weight: bold;
    border-radius: 5px;
    padding: 20px 25px 20px 25px;
    color: var(white);
    background-color: var(--color-BS5);
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    border: 1px solid var(--color-500);
}

.button p {
    font-size: 17px;
    color: var(--color-BS1);
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
}

.button:first-line{
    font-size: 20px;
    font-weight: 800!important;
}

.button:hover{
    background-color: var(--color-BS25);
    color: #000;
  }

.button:hover p {
    color: #1a1a1a;
}  
@media screen and (max-width: 1090px){

 
  .container-preview {
      padding: 2em 2em 3em 2em!important;
      grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
      gap: 2em 2em;
  }
  .container-preview a {
    color: var(--color-000);
  }

  .latest {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
    .chapter-number {
        display: flex;
        gap: 5px;
        font-size: 0.8em;
        flex-direction: column;
    }
    .chapter-number p {
        cursor: default;
        font-size: 0.9em;
        color: var(--color-BS2);
    }
}
.start {
    width: 100%;
    background-color: var(--color-BS5);
  }

  .recherche {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 25vh;
    position: relative;
    width: 100%;
  }

  .recherche h1 {
    color: var(--color-BS1);
    font-weight: 800;
    font-size: 33px;
  }

  .suite input[type="text"] {
    padding: 15px 25px 15px 15px;
    border: 2px solid var(--color-BS3);
    border-radius: 5px;
    background-color: var(--color-BS4);
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    box-shadow: none;
    width: 50vw;
  }

  input[type="text"]:focus {
    outline: none;
    border-color: #007bff;
  }
  input[type="text"]::placeholder {
  color: var(--color-500);
  }
  .suite {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  p {
    font-size: 21px;
  }

  .wrapper {
    font-family: 'Mada', sans-serif;
    height: fit-content;
    position: relative;
    z-index: 9;
    transform: scale(0.8) translateX(12%) translateY(11%);
  }

  .wrapper .title {
    font-weight: 700;
    font-size: 24px;
    color: var(--color-BS4);
  }

  .select_wrap {
    overflow-y: hidden;
    width: 270px;
    height: 100%;
    position: relative;
    user-select: none;
    border-radius: 5px;
  }

  .select_wrap .default_option {
    background: var(--color-BS3);
    backdrop-filter: br;
    color: var(--color-BS1);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    z-index: 2;
  }

  .select_wrap .default_option li {
    padding: 6px 1px 6px 15px;
    list-style: none;
  }

  .select_wrap .default_option:before {
    content: "";
    position: absolute;
    top: 21px;
    right: 18px;
    width: 6px;
    height: 6px;
    border: 2px solid;
    border-color: transparent transparent var(--color-500) var(--color-500);
    transform: rotate(-45deg);
    transition: all 0.5s ease;
    transform-origin: center;
  }

  .select_wrap .select_ul {
    position: absolute;
    left: 0;
    width: 100%;
    background: var(--color-BS3);
    border-radius: 5px;
    transform: translateY(-100%);
    margin-top: 6px;
    opacity: 0;
    height: fit-content;
    overflow: auto;
  }

  .select_wrap .select_ul li {
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    color: var(--color-BS1);
  }

  .select_wrap .select_ul li:last-of-type {
    border: none;
  }

  .select_wrap .select_ul li:first-child:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .select_wrap .select_ul li:last-child:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .select_wrap .select_ul li:hover {
    background: var(--color-BS2);
  }

  .select_wrap .option {
    display: flex;
    align-items: center;
  }

  .select_wrap .option .icon {
    width: 58px;
    height: 32px;
    margin-right: 20px;
  }

  .select_wrap .option .icon img {
    width: 100%;
    height: 100%;
  }

  .select_wrap.active .select_ul {
    opacity: 1;
    transform: translateY(0%);
  }

  .select_wrap.active .default_option:before {
    top: 26px;
    transform: rotate(-225deg);

  }

  #tri {
    margin: 0px 0px -7px 14px;
    font-size: 15px;
    padding-top: 5px;
  }
  #machinPourMettreAGauche{
  display: flex;
    flex-direction: column;

  }
@media screen and (max-width: 1090px)
{


 .wrapper {
    transform: scale(0.85);

    }
 }
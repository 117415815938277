.carousel {
  background: var(--color-100);
  background: linear-gradient(180deg, var(--color-BS5) 0, var(--color-BS4) 250%);
}

.carousel-cell,
.carousel-cell-2 {
  width: 100%;
  height: 70vh;
  margin-right: 10px;
  position: relative;
}

.carousel-cell-2 {
  position: absolute;
  opacity: 0;
}

.carousel-cell::before,
.carousel-cell-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: -moz-calc(100% + .1rem);
  height: calc(100% + 0.1rem);
  background: -moz-linear-gradient(top, hsla(0, 0%, 95.7%, 0) 0, var(--color-100) 100%);
  background: linear-gradient(180deg, hsla(0, 0%, 95.7%, 0) 0, var(--color-BS5) 250%);
  z-index: 2;
}

.carousel-cell img,
.carousel-cell-2 img {
  float: right;
  border-radius: 0px;

}

/* cell number */
.carousel-cell:before,
.carousel-cell-2:before {
  display: block;
  text-align: center;
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.info {
  cursor: default;
  margin-top: 8%;
  margin-left: 18%;
  height: 100%;
  width: 45vw;
  color: var(--color-BS1);
  display: flex;
  text-align: left;
  font-size: 20px;
  position: absolute;
  flex-direction: column;
  z-index: 20;
}

.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 10px;
  gap: 8px;
}

.tag p {
  min-width: fit-content;
  background: transparent;
  color: var(--color-000);
  padding: 4px 11px 4px 11px;
  border-radius: 4px;
  font-size: 15px!important;
  outline: 0.001em solid var(--color-BS3);
  font-weight: 600;
}

h1 {
  font-weight: 900;
}

.info button {
  border: none;
  font-weight: 700;
  margin-right: 5px;
  font-size: 23px;
  padding: 4px 25px 4px 25px;
  border-radius: 7px;
  color: var(--color-000);
  background-image: linear-gradient(30deg, var(--color-BS2) 50%, transparent 50%);
  transition: all 0.35s, background 400ms ease-in-out;
  cursor: pointer;
  outline: solid 1px var(--color-BS2);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 100%;
  background-color: transparent;
}

.info button:hover {
  outline: solid 1px var(--color-BS2);
  background-position: 0%;
  color: var(--color-1000);
}
.decouverte{
    font-weight: 600;
}
.info p {
  font-size: 19px;
}
.summaryContent{
  display: -webkit-box;
  font-size: 17px!important;
  text-align: justify;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-shadow: 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5), 0 0 2px var(--color-BS5);
  padding-right: 2px;
}
.end {
  margin-top: 15px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.latestChapter {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-BS2);
}
@media screen and (max-width: 1090px) {

  .carousel-cell, .carousel-cell-2 {
    height: 60vh;
  }

  .carousel-cell::before, .carousel-cell-2::before {
    content: "";
    position: absolute;
    width: 100%;
    height: -moz-calc(100% + .1rem);
    height: calc(100% + 0.1rem);
    background: -moz-linear-gradient(top, hsla(0, 0%, 95.7%, 0) 0, var(--color-100) 100%);
    background: linear-gradient(180deg, hsla(0, 0%, 95.7%, 0) 0, var(--color-BS4) 150%);
    z-index: 2;
  }

  .info {
    margin: 0;
    width: 75vw;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);
    left: 50%;
}

.carousel-cell img, .carousel-cell-2 img {
width: 100%;
height: 100%;
border-radius: 0px;
object-position: center;
}

.summaryContent {
display: none;
}

.end p {
display: none;
}
.tag {
display: none;
}
}
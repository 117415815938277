#lecteur {
    cursor: pointer;
    display: flex;
    justify-content: center;
    user-select: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.imgContainer {
    position: relative;
    top: 0;
    height: 100vh;
    object-fit: contain;
    max-width: 100vw;
}
.hidden{
    display: none;
}
.visible{
    display: unset;
}
.absVisible{
    display: unset!important;
}
.modeZoom{
    height: auto;
    width: 80vw;
}
.overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 200vh;
    backdrop-filter: blur(10px);
    z-index: 99;
    display: none;
}

.content {

    margin: auto;
    background: var(--color-100);
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-BS5);
}

.flex-container {
    display: flex;
    flex-direction: column;
}
.flex-container h1 {
    text-transform: capitalize;
    margin-bottom: 30px;
}
.sub-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 20px;
    align-items: center;
}

.element {
    width: fit-content;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    background-color: var(--color-BS5);
    color: var(--color-BS1);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: bold;
}
.select{
    background-color: var(--color-000);
    color: var(--color-BS5);
}
.FlecheDeSens{
    width: 25px;
    height: 25px;
}
#verti{
    gap: 5px;
}
.cacher {
    display: flex;
    gap: 50px;
    margin-bottom: 20px;
    font-size: 30px;
    align-items: center;
}

.cacher svg, .cacher i {
    cursor: pointer;
}
#fleche2{
    transition: transform 0.60s ease-out, color 0.33s ease-out!important;
}
#chapters {
    transition: all 0.45s ease-out;
}

a {
    text-decoration: none;
    color: inherit;
}

#fleche {
    font-weight: 900;
    font-size: 21px;
    transition: all 0.14s ease-out;
    user-select: none;
}

.back {
    display: flex;
    gap: 14px;
    align-items: center;
    margin-bottom: 10px;

}

.back:hover div {
    border-bottom: 1px solid var(--color-BS1);
}
.back:hover span {
    transform: translateX(-60%);
}

.centrage {
    display: flex;
    gap: 19.6%;
}

#tube {
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    margin-bottom: 6px;
    overflow: hidden;
    z-index: 9;
    opacity: 0.40;
    transition: all 0.12s ease-out;
    transform: scaleX(0.9);
}

#tube:hover {
    opacity: 1!important;
    height: 100px;
    bottom: 0px;
}

#tube:hover > .horizontal-line {
    height: 30%;
    opacity: 0.68;
    bottom: 15px;
}

#tube:hover > .bar {
    height: 30%;
    bottom: 15px;
}
#tube > div:first-child{
    border-radius: 20px 0px 0px 20px;
}
#tube > div:last-child{
    border-radius: 0px 20px 20px 0px;
}
.horizontal-line {
    opacity: 0.60;
    position: absolute;
    width: 5%;
    height: 10%;
    bottom: 0;
    background-color: var(--color-800);
    transition: all 0.13s ease;
    border: 1px solid white;
    cursor: pointer;

}

.bar {
    opacity: 0.8;
    position: absolute;
    width: 1px;
    height: 10%;
    bottom: 0;
    background-color: white;
    transition: all 0.13s ease;

}

.horizontal-line:hover::before {
    z-index: 9;
    content: attr(data-number);
    position: absolute;
    bottom: 150%;
    background-color: var(--color-1000);
    color: var(--color-000);
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 22px;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
    filter: brightness(1.2);
}
#pageAfficheur {
    z-index: 10;
    position: fixed;
    left: 50%;
    bottom: 4%;
    transform: translateX(-50%);
    background-color: var(--color-BS5);
    color: var(--color-000);
    padding: 4px 15px;
    border-radius: 4px;
    font-size: calc(1.9vh);
    pointer-events: none;
    opacity: 0;
}
.apparition{
    animation: up 1s;
}
@keyframes up {
    0% {
        opacity: 0.2;
    }
    25% {
        opacity: 0.8;
    }
    65% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
}
.pageLect .option {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: fixed;
    top: 25vh;
    left: 2.5%;
    z-index: 10;
}

.rectangle11::-webkit-scrollbar {
    display: none;
}
@media screen and (max-width: 1090px) {
    .flex-container h1 {
        text-transform: capitalize;
        margin-bottom: 30px;
        font-size: 1.2em;
    }
}